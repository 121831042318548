/* You can add global styles to this file, and also import other style files */
@import '~video.js/dist/video-js.css';
@import '@angular/material/prebuilt-themes/deeppurple-amber.css';

/* Global CSS Variables */
:root {
    --color-white: white;
    --color-black: black;
    --color-required: red;
    --color-dark: #1F1B24;
    --color-dark-rgb: 21, 27, 36;
    --color-light: #9EE493;
    --color-light-hover: #6cd65c;
    --color-grey: grey;
    --color-purple: rgb(103, 58, 183);
}

* {
    font-family: "Heebo Light", sans-serif;
}

html, body { height: 100%; }
body {margin: 0;}

.text-required{
    color: var(--color-required);
}

.white{
    color: var(--color-white);
}

.bold{
    font-weight: bold;
}

.italic{
    font-style: italic;
}

.font-bold{
    font-family: "Heebo Bold";
}

.font-light{
    font-family: "Heebo Light";
}

.font-thin{
    font-family: "Heebo Thin";
}

.font-medium{
    font-family: "Heebo Medium";
}

mat-error{
    font-family: "Heebo Medium";
}

/* CSS LOADER */
.loader {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Fonts */
@font-face {
    font-family: "Heebo Thin";
    src: url('/assets/fonts/Heebo-Thin.ttf')  format('truetype');
    font-weight: bold;
    font-display: block;
}

@font-face {
    font-family: "Heebo Light";
    src: url('/assets/fonts/Heebo-Light.ttf')  format('truetype');
    font-weight: bold;
    font-display: block;
}

@font-face {
    font-family: "Heebo Medium";
    src: url('/assets/fonts/Heebo-Medium.ttf')  format('truetype');
    font-weight: bold;
    font-display: block;
}

@font-face {
    font-family: "Heebo Bold";
    src: url('/assets/fonts/Heebo-Bold.ttf')  format('truetype');
    font-weight: bold;
    font-display: block;
}

/* Journey popup & general popup styling */

.journey-popup-dialog .mat-dialog-container {
    max-width: 90vw;
    max-height: 100vh;
    padding: 0;
    background-color: transparent;
}

/* disabled */
.disabled {
    opacity: .5;
    pointer-events: none;
  }

/* fade in */
.fade-in {
    animation: fadeIn ease 3s;
}

.faster-fade-in {
    animation: fadeIn ease 300ms;
}

@keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
}
.mat-radio-outer-circle {
    border-color: gray;
}
